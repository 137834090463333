import React from 'react';
import { Image } from '@mantine/core';
import { IImage } from './image.props';

export default function IconPlayWhite(props: IImage) {
	const { children, ...restProps } = props;
	return (
		<Image src="/icons/play-white.svg" width={24} height={24} {...restProps}>
			{children}
		</Image>
	);
}
