'use client';

import { useTranslations } from 'next-intl';
import { Box, Center, Flex } from '@mantine/core';
import { IconArrowBackUp } from '@tabler/icons-react';
import { useRouter } from 'next/navigation';
import VText from '../common/v-text';
import Gif404 from '../common/icons/404Gif';
import VButton from '../common/v-button';

export function NotFoundPage404() {
	const t = useTranslations('NotFound');
	const router = useRouter();

	const handleOnClick = () => {
		router.push('/login');
	};

	return (
		<Flex w="100%" h="100%" justify="center" align="center">
			<Box>
				<Center>
					<Box w={500} h={500}>
						<Gif404 />
					</Box>
				</Center>
				<Center>
					<Box ta="center" w={671} h={257} mt={-70}>
						<VText texttype="title" fz={40} fw={600}>
							{t('404')}
						</VText>
						<VText fz={16} fw={400}>
							{t('404Text')}
						</VText>
						<VText fz={16} fw={400}>
							{t('thanks')}
						</VText>
						<VButton w={200} onClick={() => handleOnClick()}>
							<IconArrowBackUp /> {t('redirect')}
						</VButton>
					</Box>
				</Center>
			</Box>
		</Flex>
	);
}
