import React from 'react';
import { Button } from '@mantine/core';
import { IButton } from './v-button.props';
import classes from './style.module.scss';
import IconPlayWhite from '../icons/IconPlayWhite';

export default function VButton(props: IButton) {
	const { children, ...restProps } = props;

	return props.butonvariant === 'deactive' ? (
		<Button
			className={classes.deactiveBtn}
			disabled
			mt={40}
			w={165}
			h={56}
			radius="16px"
			color="accent-01.1"
			c="dark-grey.0"
			{...restProps}
		>
			{children}
		</Button>
	) : props.butonvariant === 'warning' ? (
		<Button
			className={classes.warningBtn}
			mt={40}
			w={165}
			h={56}
			radius="16px"
			color="error.0"
			{...restProps}
		>
			{children}
		</Button>
	) : props.butonvariant === 'update' ? (
		<Button
			className={classes.updateBtn}
			mt={40}
			w={165}
			h={56}
			radius="16px"
			color="dark-grey.0"
			{...restProps}
		>
			{children}
		</Button>
	) : props.butonvariant === 'successful' ? (
		<Button
			className={classes.successfulBtn}
			mt={40}
			w={165}
			h={56}
			radius="16px"
			color="confirmation.0"
			{...restProps}
		>
			{children}
		</Button>
	) : props.butonvariant === 'get-started' ? (
		<Button
			mt={40}
			w={253}
			h={56}
			radius="16px"
			bg="white"
			c="black"
			style={{ border: '1px solid var(--mantine-color-dark-grey-4)' }}
			{...restProps}
		>
			{children}
		</Button>
	) : props.butonvariant === 'play' ? (
		<Button
			className={classes.demoBtn}
			w={198}
			h={56}
			radius="16px"
			color="accent-02.0"
			c="white"
			{...restProps}
		>
			<IconPlayWhite />
			{children}
		</Button>
	) : (
		<Button mt={40} w={166} h={56} radius="16px" bg="primary-Krmz.0" {...restProps}>
			{children}
		</Button>
	);
}
