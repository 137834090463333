import React from 'react';
import { Text } from '@mantine/core';
import { IText } from './v-import.props';
import classes from './style.module.scss';

export default function VText(props: IText) {
	const { children, ...restProps } = props;
	return (
		<Text {...restProps} className={props.texttype === 'title' ? classes.titleText : ''}>
			{children}
		</Text>
	);
}
